<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row :gutter="30">
        <el-col :span="24">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
      <div class="mt-2">
        <el-row :gutter="30">
          <el-form-item label="Field Options">
            <span style="color: red; font-size: 20px">* </span>
            <el-select
              placeholder="Enter options"
              v-model="field.options"
              :clearable="true"
              :allow-create="true"
              :default-first-option="true"
              :filterable="true"
              :multiple="true"
              :no-data-text="''"
            >
              <el-option
                v-for="(opt, index) of field.options"
                :key="index"
                :label="opt"
                :value="opt"
              >
              </el-option>
            </el-select>
            <i
              class="el-icon-edit"
              @click="openOptions"
              style="margin-left: 5px"
            ></i>
          </el-form-item>
        </el-row>
      </div>
    </el-form>
    <dialog-component
      :visible="optionsDialogVisible"
      :title="'Edit options'"
      @before-close="optionsDialogVisibleClose"
      :containerWidth="'35%'"
      :containerHeight="'55%'"
      center
    >
      <div class="tableScroll">
        <el-table
          border
          :data="dialogOptions"
          @cell-mouse-enter="editoptionsData"
          @cell-mouse-leave="resetoptionsData"
        >
          <el-table-column label="Options">
            <template slot-scope="scope">
              <div v-if="currentActiveRowIndex == scope.$index">
                <el-input
                  size="mini"
                  v-model="dialogOptions[currentActiveRowIndex]"
                ></el-input>
              </div>
              <div v-else>
                <p style="font-size: 14px" slot="reference">
                  {{ scope.row }}
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="background-color: #409eff; float: right; color: #ffffff"
          class="type-2"
          @click="saveData"
          >Save</el-button
        >
      </span>
    </dialog-component>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
// import Placeholder from "./Placeholder";
import Tag from "./Tag";

export default {
  components: {
    TitleAndDescription,
    Tag,
    // Placeholder
  },
  props: ["field"],
  data() {
    return {
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
      options: [],
      inputVisible: false,
      inputValue: "",
      dialogOptions: [],
      currentActiveRowIndex: -1,
      newOption: "",
      optionsDialogVisible: false,
    };
  },
  methods: {
    optionsDialogVisibleClose() {
      this.optionsDialogVisible = false;
    },
    openOptions() {
      if (this.isView) {
        return;
      }
      this.optionsDialogVisible = true;
    },
    saveData() {
      this.optionsDialogVisible = false;
    },
    handleInputConfirm() {
      let inputValue = this.inputValue.trim();
      let inpuValues = inputValue.split(",");

      if (inpuValues.length) {
        inpuValues.forEach((value) => {
          value = value.trim();
          if (value.length) {
            let existed = this.field.options.find((value1) => value1 == value);
            if (!existed) {
              this.field.options.push(value);
            }
          }
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.focus();
      });
    },
    handleClose(tagValue) {
      let accessionIdIndex = this.field.options.findIndex((x) => x == tagValue);
      this.field.options.splice(accessionIdIndex, 1);
    },
    editoptionsData(row) {
      this.currentActiveRowIndex = this.field.options.indexOf(row);
    },
    resetoptionsData() {
      const uniqueOptions = [...new Set(this.dialogOptions)];
      if (uniqueOptions.length < this.dialogOptions.length) {
        this.dialogOptions = uniqueOptions;
      }
      this.field.options = this.dialogOptions.slice();
      this.field.options = this.field.options.filter(function (element) {
        return element !== "";
      });
      this.currentActiveRowIndex = -1;
    },
  },
  watch: {
    optionsDialogVisible(newVal) {
      if (newVal) {
        this.dialogOptions = this.field.options.slice();
      }
    },
  },
};
</script>

<style lang="scss"></style>

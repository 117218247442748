<template>
  <div v-loading="loading">
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item class="image-upload">
            Image Upload Type
            <span style="color:red; font-size: 20px;">* </span> 
            <el-select
              v-model="field.upload_type"
              placeholder="Select Image Upload Type"
            >
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <div class="form-group">
            <Tag :field="field" />
          </div>
          
        </el-col> -->
       
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="15">
        <el-upload
            v-if="field.upload_type === 'UPLOAD'"
            class="upload-file"
            drag
            name="logo"
            :on-change="uploadImageFile"
            action
            :show-file-list="false"
            :auto-upload="false"
            accept=".png, .jpg, .jpeg"
          >
            <div v-if="!fileUrl" style="padding: 1em">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </div>
          
          </el-upload>
          </el-col>

      <el-col :span="20">
          <p :style="getStyle">
            <template v-if="field.image_url">
              <img
                style="width: 100%; height: 90%"
                :src="field.image_url"
              />
            </template>

            <template v-else>-</template>
          </p>

        </el-col> 

        <!-- <el-col v-if="this.field.value" v-loading="loading" :span="8">
          <img
            :src="this.field.value"
            alt="IMG"
            style="width: 200px; height: 150px"
          />
        </el-col> -->
     
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
// import Tag from "./Tag";
import axios from "@/config/axios";

export default {
  components: {
    TitleAndDescription,
    // Tag,
  },
  props: ["field"],
  data() {
    return {
      validations: [],
      file: "",
      image_url: "",
      loading: false,
      fileUploading: false,
      options: ["UPLOAD"],
      // options: ["UPLOAD", "LINK"],
      fileUrl: "",
      fileUploadError: "",
      imageWidth: 100,
      fileTypes: "image/jpeg,image/png",

      allowed_file_types: this.fileTypes,
      imgDataWidth: 0,
      imgDataHeight: 0,
      imgData: null,
    };
  },
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    UploadUrl() {
      return appConfig.API_URL + "/uploadToS3";
    },
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token,
      };
    },
    getImageData(){
     return this.field.image_url
    },   
    getStyle() {
      return this.hasLabel ? `height:${this.field.height - 30}px` : "";
    },
    getUploadPreviewStyle() {
      return this.fileUrl ? `width:${100 / this.fileUrl}%` : "width:100%";
    },
    ...mapGetters("companyTemplates", ["getImageUploadData"]),
    ...mapGetters("templatesData", ["getDownloadUrl"]),
    ...mapGetters("auth", ["getAuthenticationDetails"]),
    ...mapGetters('s3FileUpload', ['getFileUploadData','getFileUploadStatus']),

    getFileName() {
      let fileNames = this.field["upload_file"].name.split(".");

      if (fileNames.length > 1) {
        let name = fileNames[0];
        let ext = fileNames.pop();
        if (name.length > 20) {
          return name.slice(0, 20) + "." + ext;
        } else {
          return name + "." + ext;
        }
      } else {
        return name.slice(0, 20);
      }
    },
  },
  async mounted() {
    this.imgData =
      this.field && this.field.image_url ? this.field.image_url : "";
  },
  methods: {
    async uploadImageFile(file) {
      var self = this;
      this.loading = true;
      if (file["size"] < 2111775) {
        if (
          file.raw.type == "image/jpeg" ||
          file.raw.type == "image/png" ||
          file.raw.type == "image/jpg"
        ) {
          var formData = new FormData();
          formData.append(
            "files",
            file.raw !== undefined ? file.raw : file
          );

          await this.$store.dispatch(
            "s3FileUpload/uploadFileToS3",
            formData
          );
          if(this.getFileUploadStatus && this.getFileUploadData){
            this.loading = false;
             this.$set(this.field, "image_url", this.getFileUploadData)
            // this.field.image_url = ;
            await this.getBase64(file.raw).then((res) => {
              // get width, height of image
              var img = document.createElement("img");
              img.src = res;
              img.onload = function () {
                var w = img.width;
                var h = img.height;
                self.field.width = w;
                self.field.height = h;
                img.remove();
              };
            });
          }else{
            this.loading = false;
            this.$alert("Error while uploading file");
          }
          // self.imgDataWidth = 0;
          // self.imgDataHeight = 0;
        } else {
          this.loading = false;
          this.$alert("Please upload a image file");
        }
      } else {
        this.loading = false;
        this.$alert("File size must not exceed 2MB");
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    async uploadFile() {
      this.fileUploadError = "";
      this.loading = false;
    },
    handleLogoSuccess(res, file) {
      console.log(file);

      this.fileUrl = res.Location;
      this.imageWidth = 100 / this.fileUrl;
      (this.field.image_url = this.fileUrl), (this.loading = false);
      this.$notify.success({
        title: "success",
        message: "Updated Successfully",
      });
    },
    async beforeLogoUpload(file) {
      this.loading = true;
      let isValidMime = true;
      isValidMime = file.size / 1024 / 1024 < 2;
      if (!isValidMime) {
        this.$message.error("picture size can not exceed 2MB!");
      }
      this.loading = false;
      return isValidMime;
    },

    handleLogoError(res) {
      res = JSON.parse(res.message);
      if (!res.success) {
        this.fileUploadError = res.message;
      }
      this.loading = false;
    },

    async upload(file) {
      let formData = new FormData();
      // this.currentImage = this.$refs.file.files.item(0);
      formData.append("files", file);
      formData.append("pathRepo", "DOCUMENT");

      let uploadInfo = await axios.post("/uploadToS3", formData);
      console.log(uploadInfo);

      this.$set(this.field, "upload_file", {
        type: "DOCUMENT",
        name: file.name,
        file: file,
        new: true,
        file_name: file.name,
        file_type: file.raw.type,
        file_size_in_kb: file.size / 1024,
      });
    },

  },
};
</script>
<style scoped>
@media (max-width: 767px) {
  .image-upload{
    width:220px;
  }
}
</style>
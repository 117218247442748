<template>
    <div>
      <el-form label-position="right">
        <title-and-description :field="field" />
        <el-row type="flex" :gutter="30">
          <el-col :span="4">
            <div class="form-group-in-checkbox">
              <el-form-item label="Min Selection">
                <span style="color:red; font-size: 20px;">* </span>
                <el-input-number
                  v-model="field.min_selection"
                  :controls="false"
                ></el-input-number>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="2"></el-col>
           <el-col :span="4">
            <div class="form-group-in-checkbox">
              <el-form-item label="Max Selection">
                <span style="color:red; font-size: 20px;">* </span>
                <el-input-number
                  v-model="field.max_selection"
                  :controls="false"
                ></el-input-number>
              </el-form-item>
            </div>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="18">
            <div class="form-group-in-checkbox">
        <el-form-item label="Field Options">
          <span style="color:red; font-size: 20px;">* </span>
          <el-select
            placeholder="Enter options"
            v-model="field.options"
            :clearable="true"
            :allow-create="true"
            :default-first-option="true"
            :filterable="true"
            :multiple="true"
            :no-data-text="''"
          >
            <el-option
              v-for="(opt, index) of field.options"
              :key="index"
              :label="opt"
              :value="opt"                 
            >
          </el-option>
        </el-select>
        <i class="el-icon-edit"  @click="openOptions"></i>  
        </el-form-item>
      </div>
          </el-col>
        </el-row>
      </el-form>
      <dialog-component
          :visible="optionsDialogVisible"
          :title="'Edit Options'"
            :destroy-on-close="true"
            :containerWidth="'50%'"
            @before-close="optionsDialogVisibleClose"
            :containerHeight="'55%'"
            center>
          <div class="tableScroll">
            <el-table  
             border
            :data="dialogOptions"
            @cell-mouse-enter="editoptionsData" 
            @cell-mouse-leave="resetoptionsData" 
            >
            <el-table-column  label="Options">
              <template slot-scope="scope">
                <div v-if="currentActiveRowIndex == scope.$index">
                  <el-input size="mini" v-model="dialogOptions[currentActiveRowIndex]"></el-input>
                </div>
                <div v-else>
                  <p style="font-size: 14px" slot="reference">
                  {{ scope.row }}
                </p>  
              </div>
              </template>  
            </el-table-column>
            </el-table>
          </div>
              <span slot="footer" class="dialog-footer">
                <el-button style="background-color: #409eff;float:right;" class="type-2" 
                  @click="saveData" type="primary">Save</el-button>
                </span>
      </dialog-component>
    </div>
  </template>
  
  <script>
  export default {
    components: {
      TitleAndDescription: () => import("./TitleAndDescription"),
      
    },
    name: "MultiSelect",
    props: ["field","isView"],
    data() {
      return {
        optionsDialogVisible:false,
        validations: [],
        currentActiveRowIndex: -1,
        dialogOptions: [],
        rowsOptions: [
          {
            title: "Folder 1",
            _id: "1",
          },
          {
            title: "Folder 2",
            _id: "2",
          },
          {
            title: "Folder 3",
            _id: "3",
          },
        ],
      };
    },
    methods: {
      optionsDialogVisibleClose(){
        this.optionsDialogVisible=false;
      },
      openOptions() {
        if (this.isView) {
          return;
        }
        this.optionsDialogVisible = true;
      },
      changeField() {
        this.$set(this.form, this.field.key, !this.form[this.field.key]);
      },
      editoptionsData(row) {
        this.currentActiveRowIndex = this.field.options.indexOf(row);
      },
      resetoptionsData() {
        const uniqueOptions = [...new Set(this.dialogOptions)];
        if (uniqueOptions.length < this.dialogOptions.length) {
          this.dialogOptions = uniqueOptions;
        }
        this.field.options = this.dialogOptions.slice();
        this.field.options = this.field.options.filter(function(element) {
          return element !== "";
          });
        this.currentActiveRowIndex = -1;
      },
      saveData(){
        this.optionsDialogVisible = false;
      }
    },
    watch: {
      optionsDialogVisible(newVal) {
        if (newVal) {
          this.dialogOptions = this.field.options.slice();
        }
      },
    },
  };
  </script>
  
  <style  lang="scss">
    .form-group-in-checkbox{
  display: flex;
  }
  
  .el-select .el-select__tags {
      display: flex;
      overflow-x: hidden;
      flex-wrap: nowrap;
  }
  .tableScroll {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: scroll;
    border-top: 1px ridge;
    border-left: 1px ridge;
    border-bottom: 1px ridge;
    margin-right: 5px;
    width:100%
  }
  .tableScroll::-webkit-scrollbar {
    width: 0.5em;
  }
  .tableScroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
  }
  </style>
  